import { default as icons_45pagen2dzfnZe3KMeta } from "/builds/u-ogorod/uogorod_front/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as _91id_93Uo1YL23gzFMeta } from "/builds/u-ogorod/uogorod_front/pages/admin/catalog/brands/[id].vue?macro=true";
import { default as indexaeTUFGRqN9Meta } from "/builds/u-ogorod/uogorod_front/pages/admin/catalog/brands/index.vue?macro=true";
import { default as categoriesqeK4Q3AvI9Meta } from "/builds/u-ogorod/uogorod_front/pages/admin/catalog/categories.vue?macro=true";
import { default as _91id_93PJaNdAyw1zMeta } from "/builds/u-ogorod/uogorod_front/pages/admin/catalog/seeds/[id].vue?macro=true";
import { default as indexDCH9duGVh8Meta } from "/builds/u-ogorod/uogorod_front/pages/admin/catalog/seeds/index.vue?macro=true";
import { default as catalogKO8YfY5YNrMeta } from "/builds/u-ogorod/uogorod_front/pages/admin/catalog.vue?macro=true";
import { default as indexAPHv3VKmKqMeta } from "/builds/u-ogorod/uogorod_front/pages/admin/index.vue?macro=true";
import { default as indexoXVUIFJF9yMeta } from "/builds/u-ogorod/uogorod_front/pages/admin/pages/index.vue?macro=true";
import { default as indexS3LYAIzRekMeta } from "/builds/u-ogorod/uogorod_front/pages/admin/reports/index.vue?macro=true";
import { default as agreementsJZOuCj5BfMeta } from "/builds/u-ogorod/uogorod_front/pages/agreement.vue?macro=true";
import { default as _91code_93GtoUgG6CIPMeta } from "/builds/u-ogorod/uogorod_front/pages/auth/activate/[code].vue?macro=true";
import { default as _91code_93TEGOHbWnowMeta } from "/builds/u-ogorod/uogorod_front/pages/auth/remind/[code].vue?macro=true";
import { default as indexPvSDuDIQrlMeta } from "/builds/u-ogorod/uogorod_front/pages/auth/remind/index.vue?macro=true";
import { default as signineyPzLxDTN3Meta } from "/builds/u-ogorod/uogorod_front/pages/auth/signin.vue?macro=true";
import { default as signup3WLHnGHehvMeta } from "/builds/u-ogorod/uogorod_front/pages/auth/signup.vue?macro=true";
import { default as _91subcategory_93iwMZ2eUs9dMeta } from "/builds/u-ogorod/uogorod_front/pages/catalog/[category]/[subcategory].vue?macro=true";
import { default as indexEpLBygjbTEMeta } from "/builds/u-ogorod/uogorod_front/pages/catalog/[category]/index.vue?macro=true";
import { default as index0colJuzPm1Meta } from "/builds/u-ogorod/uogorod_front/pages/catalog/brands/[id]/index.vue?macro=true";
import { default as reviews3HAARtoPLMMeta } from "/builds/u-ogorod/uogorod_front/pages/catalog/brands/[id]/reviews.vue?macro=true";
import { default as seedshc6wkCHLd0Meta } from "/builds/u-ogorod/uogorod_front/pages/catalog/brands/[id]/seeds.vue?macro=true";
import { default as _91id_93AwimGFtgC9Meta } from "/builds/u-ogorod/uogorod_front/pages/catalog/brands/[id].vue?macro=true";
import { default as indexLku28dVRYVMeta } from "/builds/u-ogorod/uogorod_front/pages/catalog/brands/index.vue?macro=true";
import { default as indextG0cQu09gfMeta } from "/builds/u-ogorod/uogorod_front/pages/catalog/index.vue?macro=true";
import { default as product_45_91id_93d8DPHf1dgmMeta } from "/builds/u-ogorod/uogorod_front/pages/catalog/product-[id].vue?macro=true";
import { default as editLvxgTIkN41Meta } from "/builds/u-ogorod/uogorod_front/pages/feed/[id]/edit.vue?macro=true";
import { default as edit2VgDrXo70TeMeta } from "/builds/u-ogorod/uogorod_front/pages/feed/[id]/edit2.vue?macro=true";
import { default as indexhs1049jhVwMeta } from "/builds/u-ogorod/uogorod_front/pages/feed/[id]/index.vue?macro=true";
import { default as indexYXumzaEgo2Meta } from "/builds/u-ogorod/uogorod_front/pages/feed/index.vue?macro=true";
import { default as _91slug_93yBEF2I1188Meta } from "/builds/u-ogorod/uogorod_front/pages/gardens/[slug].vue?macro=true";
import { default as indexgSOJ6shQXOMeta } from "/builds/u-ogorod/uogorod_front/pages/gardens/index.vue?macro=true";
import { default as dnevnik_45sadovodaCNtZZWoyPxMeta } from "/builds/u-ogorod/uogorod_front/pages/help/dnevnik-sadovoda.vue?macro=true";
import { default as download_45appdrf4ESLCCuMeta } from "/builds/u-ogorod/uogorod_front/pages/help/download-app.vue?macro=true";
import { default as downloadXm3WlHtGIOMeta } from "/builds/u-ogorod/uogorod_front/pages/help/download.vue?macro=true";
import { default as infoL1WGH2BQOLMeta } from "/builds/u-ogorod/uogorod_front/pages/help/info.vue?macro=true";
import { default as indexrNTxjTsToLMeta } from "/builds/u-ogorod/uogorod_front/pages/index.vue?macro=true";
import { default as _91slug_93CX7U0vttlCMeta } from "/builds/u-ogorod/uogorod_front/pages/manuals/[slug].vue?macro=true";
import { default as gryadki_45v_45teplice_45svoimi_45rukamic6kKBlyVrSMeta } from "/builds/u-ogorod/uogorod_front/pages/manuals/gryadki-v-teplice-svoimi-rukami.vue?macro=true";
import { default as indexa17maaXDVEMeta } from "/builds/u-ogorod/uogorod_front/pages/manuals/index.vue?macro=true";
import { default as teplica_45iz_45polikarbonata_45svoimi_45rukamicBUSZscfJNMeta } from "/builds/u-ogorod/uogorod_front/pages/manuals/teplica-iz-polikarbonata-svoimi-rukami.vue?macro=true";
import { default as teplica_45svoimi_45rukami_45iz_45derevaZY4f21jdreMeta } from "/builds/u-ogorod/uogorod_front/pages/manuals/teplica-svoimi-rukami-iz-dereva.vue?macro=true";
import { default as teplica_45svoimi_45rukami_45iz_45profilnoy_45trubyNSlHlkT2QvMeta } from "/builds/u-ogorod/uogorod_front/pages/manuals/teplica-svoimi-rukami-iz-profilnoy-truby.vue?macro=true";
import { default as teplitsa_45svoimi_45rukamijEFC1Y5DZPMeta } from "/builds/u-ogorod/uogorod_front/pages/manuals/teplitsa-svoimi-rukami.vue?macro=true";
import { default as o_45proektePL7167UmjJMeta } from "/builds/u-ogorod/uogorod_front/pages/o-proekte.vue?macro=true";
import { default as print_45_91_46_46_46segments_93yT7csm266FMeta } from "/builds/u-ogorod/uogorod_front/pages/print-[...segments].vue?macro=true";
import { default as privacydXe3lqGNeLMeta } from "/builds/u-ogorod/uogorod_front/pages/privacy.vue?macro=true";
import { default as blogq9ld9qHDwEMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/blog.vue?macro=true";
import { default as commentsurpjvcusyEMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/comments.vue?macro=true";
import { default as editGpNHgx6yxUMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/edit.vue?macro=true";
import { default as experimentsvertTLIrdAMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/experiments.vue?macro=true";
import { default as favoriteg1RW9tkRF6Meta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/favorite.vue?macro=true";
import { default as _91_46_46_46segments_93_45old9A37ro9oklMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/gardens/[...segments]-old.vue?macro=true";
import { default as _91_46_46_46segments_93274sWxyTqgMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/gardens/[...segments].vue?macro=true";
import { default as gardens9NeCn838DTMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/gardens.vue?macro=true";
import { default as groupscFGkVFgymeMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/groups.vue?macro=true";
import { default as grownogigr2DZQNMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/grown.vue?macro=true";
import { default as indexQ3jEqHPLB6Meta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/index.vue?macro=true";
import { default as recordsYhjD16SXlzMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/records.vue?macro=true";
import { default as reviewsSM2rY55IzuMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/reviews.vue?macro=true";
import { default as seedsoD0GHQf14OMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/seeds.vue?macro=true";
import { default as settingsUYkDeeHKuQMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id]/settings.vue?macro=true";
import { default as _91id_93ZEFrWt2H4jMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/[id].vue?macro=true";
import { default as indexQFOVKSupwzMeta } from "/builds/u-ogorod/uogorod_front/pages/profiles/index.vue?macro=true";
import { default as _91subcategory_93C0qTBlbsugMeta } from "~/pages/catalog/[category]/[subcategory].vue?macro=true";
import { default as component_45stubyVLxAwqLKbMeta } from "/builds/u-ogorod/uogorod_front/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubyVLxAwqLKb } from "/builds/u-ogorod/uogorod_front/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "icons-page",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/builds/u-ogorod/uogorod_front/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue")
  },
  {
    name: "admin-catalog",
    path: "/admin/catalog",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/catalog.vue"),
    children: [
  {
    name: "admin-catalog-brands-id",
    path: "brands/:id()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/catalog/brands/[id].vue")
  },
  {
    name: "admin-catalog-brands",
    path: "brands",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/catalog/brands/index.vue")
  },
  {
    name: "admin-catalog-categories",
    path: "categories",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/catalog/categories.vue")
  },
  {
    name: "admin-catalog-seeds-id",
    path: "seeds/:id()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/catalog/seeds/[id].vue")
  },
  {
    name: "admin-catalog-seeds",
    path: "seeds",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/catalog/seeds/index.vue")
  }
]
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexAPHv3VKmKqMeta || {},
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/index.vue")
  },
  {
    name: "admin-pages",
    path: "/admin/pages",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/pages/index.vue")
  },
  {
    name: "admin-reports",
    path: "/admin/reports",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/admin/reports/index.vue")
  },
  {
    name: "agreement",
    path: "/agreement",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/agreement.vue")
  },
  {
    name: "auth-activate-code",
    path: "/auth/activate/:code()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/auth/activate/[code].vue")
  },
  {
    name: "auth-remind-code",
    path: "/auth/remind/:code()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/auth/remind/[code].vue")
  },
  {
    name: "auth-remind",
    path: "/auth/remind",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/auth/remind/index.vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/auth/signin.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/auth/signup.vue")
  },
  {
    name: "catalog-category-subcategory",
    path: "/catalog/:category()/:subcategory()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/[category]/[subcategory].vue")
  },
  {
    name: "catalog-category",
    path: "/catalog/:category()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/[category]/index.vue")
  },
  {
    name: _91id_93AwimGFtgC9Meta?.name,
    path: "/catalog/brands/:id()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/brands/[id].vue"),
    children: [
  {
    name: "catalog-brands-id",
    path: "",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/brands/[id]/index.vue")
  },
  {
    name: "catalog-brands-id-reviews",
    path: "reviews",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/brands/[id]/reviews.vue")
  },
  {
    name: "catalog-brands-id-seeds",
    path: "seeds",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/brands/[id]/seeds.vue")
  }
]
  },
  {
    name: "catalog-brands",
    path: "/catalog/brands",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/brands/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/index.vue")
  },
  {
    name: "catalog-product-id",
    path: "/catalog/product-:id()",
    meta: product_45_91id_93d8DPHf1dgmMeta || {},
    component: () => import("/builds/u-ogorod/uogorod_front/pages/catalog/product-[id].vue")
  },
  {
    name: "feed-id-edit",
    path: "/feed/:id()/edit",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/feed/[id]/edit.vue")
  },
  {
    name: "feed-id-edit2",
    path: "/feed/:id()/edit2",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/feed/[id]/edit2.vue")
  },
  {
    name: "feed-id",
    path: "/feed/:id()",
    meta: indexhs1049jhVwMeta || {},
    component: () => import("/builds/u-ogorod/uogorod_front/pages/feed/[id]/index.vue")
  },
  {
    name: "feed",
    path: "/feed",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/feed/index.vue")
  },
  {
    name: "gardens-slug",
    path: "/gardens/:slug()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/gardens/[slug].vue")
  },
  {
    name: "gardens",
    path: "/gardens",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/gardens/index.vue")
  },
  {
    name: "help-dnevnik-sadovoda",
    path: "/help/dnevnik-sadovoda",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/help/dnevnik-sadovoda.vue")
  },
  {
    name: "help-download-app",
    path: "/help/download-app",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/help/download-app.vue")
  },
  {
    name: "help-download",
    path: "/help/download",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/help/download.vue")
  },
  {
    name: "help-info",
    path: "/help/info",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/help/info.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexrNTxjTsToLMeta || {},
    component: () => import("/builds/u-ogorod/uogorod_front/pages/index.vue")
  },
  {
    name: "manuals-slug",
    path: "/manuals/:slug()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/manuals/[slug].vue")
  },
  {
    name: "manuals-gryadki-v-teplice-svoimi-rukami",
    path: "/manuals/gryadki-v-teplice-svoimi-rukami",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/manuals/gryadki-v-teplice-svoimi-rukami.vue")
  },
  {
    name: "manuals",
    path: "/manuals",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/manuals/index.vue")
  },
  {
    name: "manuals-teplica-iz-polikarbonata-svoimi-rukami",
    path: "/manuals/teplica-iz-polikarbonata-svoimi-rukami",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/manuals/teplica-iz-polikarbonata-svoimi-rukami.vue")
  },
  {
    name: "manuals-teplica-svoimi-rukami-iz-dereva",
    path: "/manuals/teplica-svoimi-rukami-iz-dereva",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/manuals/teplica-svoimi-rukami-iz-dereva.vue")
  },
  {
    name: "manuals-teplica-svoimi-rukami-iz-profilnoy-truby",
    path: "/manuals/teplica-svoimi-rukami-iz-profilnoy-truby",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/manuals/teplica-svoimi-rukami-iz-profilnoy-truby.vue")
  },
  {
    name: "manuals-teplitsa-svoimi-rukami",
    path: "/manuals/teplitsa-svoimi-rukami",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/manuals/teplitsa-svoimi-rukami.vue")
  },
  {
    name: "o-proekte",
    path: "/o-proekte",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/o-proekte.vue")
  },
  {
    name: "print-segments",
    path: "/print-:segments(.*)*",
    meta: print_45_91_46_46_46segments_93yT7csm266FMeta || {},
    component: () => import("/builds/u-ogorod/uogorod_front/pages/print-[...segments].vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/privacy.vue")
  },
  {
    name: _91id_93ZEFrWt2H4jMeta?.name,
    path: "/profiles/:id()",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id].vue"),
    children: [
  {
    name: "profiles-id-blog",
    path: "blog",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/blog.vue")
  },
  {
    name: "profiles-id-comments",
    path: "comments",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/comments.vue")
  },
  {
    name: "profiles-id-edit",
    path: "edit",
    meta: editGpNHgx6yxUMeta || {},
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/edit.vue")
  },
  {
    name: "profiles-id-experiments",
    path: "experiments",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/experiments.vue")
  },
  {
    name: "profiles-id-favorite",
    path: "favorite",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/favorite.vue")
  },
  {
    name: "profiles-id-gardens",
    path: "gardens",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/gardens.vue"),
    children: [
  {
    name: "profiles-id-gardens-segments-old",
    path: ":segments(.*)*-old",
    meta: _91_46_46_46segments_93_45old9A37ro9oklMeta || {},
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/gardens/[...segments]-old.vue")
  },
  {
    name: "profiles-id-gardens-segments",
    path: ":segments(.*)*",
    meta: _91_46_46_46segments_93274sWxyTqgMeta || {},
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/gardens/[...segments].vue")
  }
]
  },
  {
    name: "profiles-id-groups",
    path: "groups",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/groups.vue")
  },
  {
    name: "profiles-id-grown",
    path: "grown",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/grown.vue")
  },
  {
    name: "profiles-id",
    path: "",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/index.vue")
  },
  {
    name: "profiles-id-records",
    path: "records",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/records.vue")
  },
  {
    name: "profiles-id-reviews",
    path: "reviews",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/reviews.vue")
  },
  {
    name: "profiles-id-seeds",
    path: "seeds",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/seeds.vue")
  },
  {
    name: "profiles-id-settings",
    path: "settings",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/[id]/settings.vue")
  }
]
  },
  {
    name: "profiles",
    path: "/profiles",
    component: () => import("/builds/u-ogorod/uogorod_front/pages/profiles/index.vue")
  },
  {
    name: _91subcategory_93C0qTBlbsugMeta?.name,
    path: "/tops/:subcategory",
    meta: {"title":"Лучшие из лучших %category%"},
    component: () => import("~/pages/catalog/[category]/[subcategory].vue")
  },
  {
    name: component_45stubyVLxAwqLKbMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubyVLxAwqLKb
  },
  {
    name: component_45stubyVLxAwqLKbMeta?.name,
    path: "/catalog-sitemap.xml",
    component: component_45stubyVLxAwqLKb
  },
  {
    name: component_45stubyVLxAwqLKbMeta?.name,
    path: "/brands-sitemap.xml",
    component: component_45stubyVLxAwqLKb
  },
  {
    name: component_45stubyVLxAwqLKbMeta?.name,
    path: "/feed-sitemap.xml",
    component: component_45stubyVLxAwqLKb
  },
  {
    name: component_45stubyVLxAwqLKbMeta?.name,
    path: "/products-sitemap.xml",
    component: component_45stubyVLxAwqLKb
  },
  {
    name: component_45stubyVLxAwqLKbMeta?.name,
    path: "/profiles-sitemap.xml",
    component: component_45stubyVLxAwqLKb
  }
]