import { defineNuxtPlugin } from 'nuxt/app'
import plugin from 'vue-yandex-maps'

const settings = {
	apiKey: 'df99a958-bc71-4fcc-9c06-acf74c02c56d', // Индивидуальный ключ API
	lang: 'ru_RU', // Используемый язык
	coordorder: 'latlong', // Порядок задания географических координат
	debug: false, // Режим отладки
	version: '2.1', // Версия Я.Карт
}

export default defineNuxtPlugin(nuxtApp => {
	nuxtApp.vueApp.use(plugin, settings)
})
