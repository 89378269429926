export type INotification = {
    title: string
    body: string
    tag?: 'warn' | 'info' | 'success'
    actions?: Array<INotificationAction>
    timeout?: number
    // eslint-disable-next-line @typescript-eslint/ban-types
    close?: Function
    type?: 'modal'
}

export type INotificationAction  = {
    // eslint-disable-next-line @typescript-eslint/ban-types
    action?: Function,
    title: string,
}

export const notifications = ref<Set<INotification>>(new Set())
export const addNotification = (notification: INotification) => {
    notification.close = removeNotification.bind(null, notification);
    notifications.value = new Set([notification, ...notifications.value]);
    if (notification.timeout !== 0) setTimeout(notification.close, notification.timeout || 2000);
}
export const removeNotification = (notification: INotification) => {
    notifications.value.delete(notification);
}
