import { createClient, mapExchange, ssrExchange, cacheExchange, fetchExchange, Client, subscriptionExchange } from '@urql/core';
import { type AuthConfig, type AuthUtilities, authExchange } from '@urql/exchange-auth';
import { defineNuxtPlugin } from '#app'
import { ref } from "vue";
import { createClient as createWSClient } from 'graphql-ws';

const ssrKey = '__URQL_DATA__'

export default defineNuxtPlugin(nuxt => {
  const { vueApp } = nuxt
  const config = useRuntimeConfig();

  const ssr = ssrExchange({
    isClient: process.client
  })

  // when app is created in browser, restore SSR state from nuxt payload
  if (process.client) {
    nuxt.hook('app:created', () => {
      ssr.restoreData(nuxt.payload[ssrKey] as any)
    })
  }

  // when app has rendered in server, send SSR state to client
  if (process.server) {
    nuxt.hook('app:rendered', (event) => {
      nuxt.payload[ssrKey] = ssr.extractData()
    })
  }

  const wsClient = process.client ? createWSClient({
    url: `${config.public.API_BASE_URL}/v1/graphql`,
    retryAttempts: Infinity,
    shouldRetry: () => true,
    keepAlive: 10000,
  }) : null;

  const client = createClient({
    url: `${config.public.API_BASE_URL}/v1/graphql`,
    // requestPolicy: 'cache-and-network',
    fetchOptions: {
      credentials: 'include',
    },
    exchanges: [
        cacheExchange,
        mapExchange({
          onResult(result) {
            if (result.error && process.client) {
              addNotification({
                tag: 'warn',
                title: result.operation.query.definitions[0].name.value,
                body: result.error.message,
                timeout: 0,
                actions: [
                  { title: 'Закрыть' }
                ]
              });
            }
          },
          onError(error, _operation) {
            console.log(error, _operation);
            const isAuthError = error.graphQLErrors.some(e => e.extensions?.code === 'invalid-jwt');
          },
        }),
        authExchange(async (utils:AuthUtilities):Promise<AuthConfig> => {
          return {
            // FIXME 404, когда есть протухший access_token
            addAuthToOperation(operation) {
              const accessToken = useCookie('access_token').value;

              if (!accessToken) return operation;

              return utils.appendHeaders(operation, {
                Cookie: `access_token=${accessToken}`
              });
            },
            didAuthError(error, _operation) {
              return error.graphQLErrors.some(e => e.extensions?.code === 'invalid-jwt');
            },
            async refreshAuth() {
              try {
                if (process.client) {
                  await $fetch('/api/auth/refresh', { method: 'POST' });
                }
              } catch(error) {
                console.log('error', error);
              }
            }
          };
        }),
        ssr, // Add `ssr` in front of the `fetchExchange`
        fetchExchange,
        subscriptionExchange({
          forwardSubscription(request) {
            const input = { ...request, query: request.query || '' };
            return {
              subscribe(sink) {
                const unsubscribe = wsClient.subscribe(input, sink);
                return { unsubscribe };
              },
            };
          },
        })
    ],
  })

  nuxt.provide('urql', client)
  vueApp.provide('$urql', ref(client))
})

declare module '#app' {
  interface NuxtApp {
    $urql: Client
  }
}