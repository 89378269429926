import validate from "/builds/u-ogorod/uogorod_front/node_modules/nuxt/dist/pages/runtime/validate.js";
import google_45tag_45global from "/builds/u-ogorod/uogorod_front/middleware/google-tag.global.ts";
import user_45updated_45at_45global from "/builds/u-ogorod/uogorod_front/middleware/user-updated-at.global.ts";
import manifest_45route_45rule from "/builds/u-ogorod/uogorod_front/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  google_45tag_45global,
  user_45updated_45at_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}