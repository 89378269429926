export const ONE_MINUTE = 60;
export const ONE_HOUR = 60 * ONE_MINUTE;
export const ONE_DAY = 24 * ONE_HOUR;
export const ONE_MONTH = ONE_DAY * 30;
export const AUTH_TOKEN_LIFE = ONE_MONTH * 6 - 1;
export const MONTHS =  Array.from(Array(12), (e,i) => new Date(25e8*++i).toLocaleString(undefined, { month: 'long' }));
export const SEED_SPEC_VALUES = {
    "sort" :"Сорт",
    "hybrid" :"Гибрид",
    "outdoor" :"Для открытого грунта",
    "greenhouse" :"Для теплицы",
    "inhouse" :"Для дома",
    "annual" :"Однолетний",
    "biennial" :"Двулетний",
    "perennial" :"Многолетний",
    "bee" :"Пчелоопыляемый",
    "self" :"Самоопыляемый",
    "ordinary" :"Обыкновенный",
    "shtamb" :"Штамбовый",
    "ampelous" :"Ампельный",
    "very-early" :"Очень ранний",
    "early" :"Ранний",
    "mid-early" :"Среднеранний",
    "mid" :"Среднего срока",
    "mid-late" :"Среднепоздний",
    "very-late" :"Очень поздний",
    "late" :"Поздний",
    "remontant" :"Ремонтантный",
    "indet" :"Индетерминантный",
    "det" :"Детерминантный",
    "super-det" :"Супердетерменантный",
    "semi-det" :"Полудетерминантный",
    "red" :"Красный",
    "orange" :"Оранжевый",
    "yellow" :"Жёлтый",
    "green" :"Зеленый",
    "cyan" :"Голубой",
    "blue" :"Синий",
    "black" :"Чёрный",
    "pink" :"Розовый",
    "brown" :"Коричневый",
    "crimson" :"Малиновый",
    "fulvous" :"Бурый",
    "white" :"Белый",
    "purple" :"Фиолетовый",
    "bicolor" :"Биколор",
    "lilac" :"Сиреневый",
    "coral" :"Коралловый",
    "mixed" :"Смесь окрасок",
    "striped" :"Полосатый",
};

export const IMAGE_PRESETS = {
    DEFAULT: {
        sizes: {
            width: 768,
            height: 768
        },
        options: {
            fit: 'inside',
            withoutEnlargement: true
        }
    },
    USERPIC: {
        sizes: {
            width: 200,
            height: 200,
        },
        options: {
            fit: 'cover',
            withoutEnlargement: true
        }
    },  
}