import type { CookieRef } from "#app";
import { ONE_MINUTE } from "~/constants";

export default defineNuxtRouteMiddleware(async (to, from) => {
	if (import.meta.server) return;

	const isSameRoute = to.name == from.name;
	const client:CookieRef<any> = useCookie('uogorod.client');
	
	const isMenuOpen = useState('drawer');

	isMenuOpen.value = false;

	document.activeElement?.blur();

	// Не обновляем последний визит если это тот-же урл или анонимный пользователь
	if (isSameRoute || !client.value?.client_id) {
		return;
	};

	// Обновляем последний визит не чаще чем раз в 5 минут
	const now = Date.now();
	const lastSeen = useState('last_seen', () => now);
	const elapsedSeconds = (now - lastSeen.value) / 1000;

	if (elapsedSeconds < ONE_MINUTE * 5) {
		return;
	}
	
	lastSeen.value = now;

	useNuxtApp().$urql.mutation(UpdateProfileDocument, {
		id: client.value.client_id,
		set: {
			updated_at: "now()"
		}
	}, {
		requestPolicy: 'cache-only'
	}).toPromise();
})
