<template>
    <el-config-provider :locale="locale">
        <NuxtPwaManifest />
        <NuxtLayout :links="navigationLinks">
            <NuxtLoadingIndicator color="#facc15" errorColor="#dc2626" />
            <NuxtPage />
        </NuxtLayout>
    </el-config-provider>
</template>

<script setup>
import ru from "element-plus/dist/locale/ru.min.mjs";

useHead({
    title: "Uogorod.ru",
    meta: [{ name: "description", content: "description" }],
});

const locale = computed(() => ru);
const client = useUser();
const isAdmin = computed(() => client.value?.type == "Administrator");
const navigationLinks = useState("navigationLinks");

await callOnce(async () => {
    const CATEGORY_IMAGES = {
        3: "vegetables",
        513: "chem",
        101: "trees",
        9: "grass",
        4: "berries",
        1: "flowers",
    };

    function mapCatalogLinks({ id, title, slug, childrens }) {
        return {
            id,
            title,
            path: `/catalog/${this ? this + "/" : ""}${slug}`,
            icon: CATEGORY_IMAGES[id],
            childrens: childrens
                ? childrens.map(mapCatalogLinks.bind(slug))
                : null,
        };
    }

    const [categoriesResponse, brandsResponse] = await Promise.all([
        useGetRootCategoriesQuery(),
        useGetBrandsForSelectQuery(),
    ]);

    navigationLinks.value = [
        {
            title: "Лента",
            path: "/feed",
            icon: "fluent:feed-28-regular",
        },
        {
            title: "Люди",
            path: "/profiles",
            icon: "icon-park-outline:road-sign-both",
        },
        {
            title: "Каталог",
            path: "/catalog",
            icon: "fluent-emoji-high-contrast:lotus",
            childrens: [
                ...categoriesResponse.data.value?.uogorod_seed_categories.map(
                    mapCatalogLinks,
                ),
                {
                    title: "Бренды",
                    path: "/catalog/brands",
                    icon: "briefcase",
                    childrens: brandsResponse.data.value.uogorod_brands.map(
                        ({ title, id }) => ({
                            title,
                            path: `/catalog/brands/${id}`,
                        }),
                    ),
                },
            ],
        },
        {
            title: "Руководства",
            path: "/manuals",
            icon: "material-symbols:menu-book-outline-rounded",
        },
        {
            title: "Помощь",
            path: "/help/info",
            class: "mt-auto border-t pt-4",
            icon: "vector-help",
            childrens: [
                {
                    title: "Как пользоваться дневником",
                    path: "/help/dnevnik-sadovoda",
                },
            ],
        },
        ...(isAdmin.value
            ? [
                  {
                      title: "Админка",
                      path: "/admin/catalog/seeds",
                      class: "mt-auto border-t pt-4",
                      icon: "eos-icons:admin-outlined",
                      childrens: [
                          {
                              title: "Каталог",
                              path: "/admin/catalog/seeds",
                          },
                          {
                              title: "Страницы",
                              path: "/admin/pages",
                          },
                          {
                              title: "Жалобы",
                              path: "/admin/reports",
                          },
                      ],
                  },
                  {
                      title: "Модерация",
                      path: "/moderate",
                      icon: "material-symbols:filter-alt-outline-g",
                  },
              ]
            : []),
    ];
});
</script>
